<template>
  <div>


    <div class="card" style="padding: 10px;z-index: 100;border: 2px solid black">
      <!--    <router-link :to="{ path: this.link}">-->
      <!--      <div class="">-->
      <!--        <img v-lazy="this.imgUrl" :alt="this.subTitle" class="avatar" align="left"-->
      <!--             style="border: 1px solid #66cdaa;margin-right: 10px;vertical-align:middle;height: 30px;border-radius: 30px;width: 30px;">-->
      <!--        <h4 style="font-weight: normal;margin-top: 5px;font-size: 0.7rem">{{ this.title }}</h4>-->
      <!--        <div style="position: relative;top: -20px;left: 40px">-->
      <!--          <small style="">{{ this.location }}</small>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </router-link>-->
      <div>

        <!--      <iframe v-if="!this.highlight.videoId" style="border-radius:12px;"-->
        <!--              :src="`https://open.spotify.com/embed/track/${this.spotifyId}?utm_source=generator&theme=0`"-->
        <!--              width="100%" height="100" frameBorder="0" allowfullscreen=""-->
        <!--              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"-->
        <!--              loading="lazy"></iframe>-->
        <lazy-video-player v-if="this.highlight.videoId"
                           :videoUrl="`https://www.youtube.com/embed/${this.highlight.videoId}`"
                           :videoId="this.highlight.videoId"></lazy-video-player>


        <div class="pull-right"> {{activeIdx + 1}}/{{this.highLightConfig.length}} </div>
        <small>*source: youtube</small>

        <br v-if="this.highlight.videoId">
<!--          <div style="width: 100%">-->
<!--            <small v-html="this.highlight.Title" style="margin-top: -10px;">-->

<!--            <small v-html="this.highlight.subTitle" style="margin-top: -10px;">-->
<!--            </small>-->
<!--            <small v-html="this.highlight.content" style="margin-top: -5px">-->
<!--            </small>-->
<!--          </div>-->
        <a class="btn btn-sm btn-primary" style="margin: 10px" @click="activeIdx--"  :disabled="activeIdx === 0">< PREV</a>
        <a class="btn btn-sm btn-primary" style="margin: 10px" @click="activeIdx++"
           :disabled="activeIdx === (this.highLightConfig.length - 1)">NEXT></a>
        <br>

<!--        <span class="btn btn-info" v-for="(_video,idx) in highLightConfig"-->
<!--              @click="activeIdx = idx" style="padding-left: 5px" :class="idx === activeIdx && 'btn btn-primary'">-->
<!--    {{ idx + 1 }}-->
<!--  </span>-->
      </div>

    </div>

  </div>
</template>
<script>
import LazyVideoPlayer from "@/components/LazyVideoPlayer/LazyVideoPlayer";
import Collapse from "@/components/Collapse/Collapse";
import CollapseItem from "@/components/Collapse/CollapseItem";
import HighLightsConfiguration from '../../common/highlightsConfig';

export default {
  name: 'HighLightCard',
  created: function () {
    this.highLightConfig = this.shuffleArray(HighLightsConfiguration.nye_2024.config);
    // this.highlight = this.highLightConfig[0];
  },
  methods: {
    action(actionName) {
      this.showAction = actionName;
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    }
  },
  data: function () {
    return {
      highLightConfig: [],
      activeIdx: 0,
      showAction: false,
    }
  },
  components: {
    CollapseItem,
    HighLightsConfiguration,
    Collapse,
    LazyVideoPlayer,
  },
  computed: {

    highlight: {
      get: function () {
        return this.highLightConfig[this.activeIdx];
      }

    },

    isCollapsed: {
      get: function () {
        return this.collapsed
      },
      set: function (value) {
        this.$emit('listchange', value)
      }
    }
  },
  props: {
    link: {
      type: String,
    },
    imgUrl: {
      type: String,
    },
    videoId: {
      type: String,
      default: null
    },
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
    spotifyId: {
      type: String,
    },
    location: {
      type: String,
    },
    content: {
      type: String,
    },
    collapsed: {
      type: Boolean,
      default: true
    },
    collapse: {
      type: Boolean,
      default: true
    }
  },
};
</script>
