export default Object.freeze(
  {
    nye_2024: {
      config: [
        {
          title: "SAINTE VIE",
          location: 'Tulum, Mexico',
          subTitle: " <p><strong>Sainte Vie</strong>&nbsp;#MEXICAN Hero.<br>Musical-Talking Stories</p>",
          content: "<br><strong>🗓  PLAYS FEB 03</strong><br> " +
            "Mr Vie will be part of the gang in charge to close the zamna festival season. A great selection for an epic night. 📍ZAMNA CLOSING",
          link: '/event/closing-party-zamna-tulum-february-2024',
          videoId: 'mSOXgy0SRvI'
        },
        {
          title: "Anyma & Chris Avantgarde - Eternity [Live from Afterlife Tulum]",
          location: 'ZAMNA, TULUM',
          subTitle: " <p><strong>Anyma</strong>&nbsp;Modern beats of a new era<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍AFTERLIFE TULUM</a>" +
            " <p>ANYMA</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: '82-5u3H1-yk'
        },

        // {
        //   title: "POLO & PAN @ Serre Monumentale",
        //   location: 'Paris, France',
        //   subTitle: " <p><strong>Polo & Pan</strong>&nbsp;Timeless music that flicks like frames through Golden Era Hollywood films <br></p>",
        //   content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍BAGATELLE TULUM</a>" +
        //     " <p>POLO & PAN will land this Dec 29 to Bagatelle for an Epic night. </p> Two days later Polo Corp the solo project of will perform on Piknik Vagalume" +
        //     "</a>",
        //   link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
        //   videoId: 'CsGauHXioos?start=2520'
        // },
        {
          title: "Goldcap @ SET Underground",
          location: 'Tulum, Mexico',
          subTitle: " <p><strong>JUNGLE EXPERIENCE BY SET UNDERGROUND</strong>&nbsp;.<br></p>",
          content: "<br><strong>🗓 SAT DEC.31 </strong> " +
            "The Jungle SET Underground parties will set on fire this NYE, for an epic celebration. ",
          link: '/event/nye-2024-set-underground-tulum-31-december-2023',
          videoId: 'qNAmeag0QkE',
        },
        {
          title: "Anyma & Chris Avantgarde - Eternity [Live from Afterlife Tulum]",
          location: 'PAPAYA PLAYA',
          subTitle: " <p><strong>Anyma</strong>&nbsp;Modern beats of a new era<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍AFTERLIFE TULUM</a>" +
            " <p>ANYMA</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: 'EfZu4BCi644'
        },
        {
          title: "BLOND:ISH x Storytellers Sunrise Mix ",
          location: 'PAPAYA PLAYA',
          subTitle: " <p><strong>BLOND:IS</strong>&nbsp;An institution on the dancing arena<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍AFTERLIFE TULUM</a>" +
            " <p>ANYMA</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: '3XFzRrUYlwY'
        },
        {
          title: "Argy & Goom Gum - Pantheon",
          location: 'ZAMNA TULUM',
          subTitle: " <p><strong>ARGY</strong>&nbsp;<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍AFTERLIFE TULUM</a>" +
            " <p>ANYMA</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: 'qX9JPWYiFfM'
        },
        {
          title: "Adam Port & Monolink - Point of No Return",
          location: 'ZAMNA TULUM',
          subTitle: " <p><strong>Adam Port & Monolin</strong>&nbsp;<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍TULUM</a>" +
            " <p>Adam Port & Monolink</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: 'Wf3cLHRw4sw'
        },
        {
          title: "Mayan Warrior - Tulum 2020",
          location: 'ZAMNA TULUM',
          subTitle: " <p><strong>Mayan Warrior</strong>&nbsp;<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍TULUM</a>" +
            " <p>Adam Port & Monolink</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: 'HzfaMOAQ-90'
        },
        {
          title: "DAMIAN LAZARUS sunrise",
          location: 'DAY ZERO',
          subTitle: " <p><strong>DAMIAN LAZARUS</strong>&nbsp;<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍TULUM</a>" +
            " <p>ADAY ZERO</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: 'nF1UIC6chjw'
        },
        {
          title: "Armen Miran (opening Jungle party set underground Tulum )2022\ne",
          location: 'DAY ZERO',
          subTitle: " <p><strong>DAMIAN LAZARUS</strong>&nbsp;<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍TULUM</a>" +
            " <p>ADAY ZERO</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: 'QkniYaPbePQ'
        },
        {
          title: "Nick Warren | Beats of Nature & The Soundgarden",
          location: 'DAY ZERO',
          subTitle: " <p><strong>DAMIAN LAZARUS</strong>&nbsp;<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍TULUM</a>" +
            " <p>ADAY ZERO</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: '76EvlsRs-yU'
        },
        {
          title: "Luca Saporito (Audiofly)",
          location: 'DAY ZERO',
          subTitle: " <p><strong>DAMIAN LAZARUS</strong>&nbsp;<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍TULUM</a>" +
            " <p>ADAY ZERO</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: 's3QvauwhT60'
        },
        {
          title: "MICHAEL BIBI @ BONBONNIERE TULUM ",
          location: 'DAY ZERO',
          subTitle: " <p><strong>DAMIAN LAZARUS</strong>&nbsp;<br></p>",
          content: "<br><strong>🗓  MON JAN.02 <br> <a href='tulum-clubs/bagatelle-tulum'>📍TULUM</a>" +
            " <p>ADAY ZERO</p>" +
            "</a>",
          link: '/event/polo-pan-tom-collins-rosa-negra-beach-tulum-january-2023?utm_source=hl',
          videoId: 'vKZmKy3BPjM'
        },
      ]
    },

  }
);



